import {Layout, Menu} from 'antd';
import {CalendarOutlined, CheckOutlined, FormOutlined, PoweroffOutlined} from '@ant-design/icons';
import {Link, Route, useHistory} from 'react-router-dom';
import {APP_CALENDAR, APP_DATA, APP_RESERVATIONS, AUTH_LOGIN} from "./urls";
import {CalendarPage} from "./CalendarPage";
import {LogoutModal} from "./LogoutModal";
import {useContext, useState} from 'react';
import {getApi} from "./api";
import {KEYS, set} from "./storage";
import {Context} from "./context/Store";
import {MyReservationsPage} from "./MyReservationsPage";
import {MyDataPage} from "./MyDataPage";
import {HeaderText} from './HeaderText';

const {Header, Content, Sider} = Layout;


export function AppPage() {
    let history = useHistory();
    const [modalVisible, setModalVisible] = useState(false);
    // eslint-disable-next-line
    const [state, dispatch] = useContext(Context);

    const logoutModalProps = {
        visible: modalVisible,
        onCancelCallback: () => setModalVisible(false),
        onOkCallback: function () {
            getApi().logout().then(function () {
                setModalVisible(false);
                history.push(AUTH_LOGIN);

                //Order is important - app does not know how to render w/o state.user
                dispatch({type: 'SET_USER', payload: undefined});
                dispatch({type: 'SET_SPOTS', payload: []});
                set(KEYS.AUTH_TOKEN, undefined);
            })
        }
    };

    function onClick(item) {
        if (item.key === "4") {
            setModalVisible(true);
        }

        //hac
        let elem = document.querySelector('.ant-layout-sider-zero-width-trigger-left');
        if (elem !== null) {
            elem.click();
        }

    }

    console.log('render: AppPage')

    return (
        <Layout>
            <Sider
                width={170}
                breakpoint="sm"
                collapsedWidth="0"
            >
                <Menu
                    mode="inline"
                    defaultSelectedKeys={['1']}
                    theme="dark"
                    onClick={onClick}
                >
                    <Menu.ItemGroup>

                        <Menu.Item key="1" icon={<CheckOutlined/>}>
                            <Link to={APP_RESERVATIONS}>Moje rezerwacje</Link>
                        </Menu.Item>

                        <Menu.Item key="2" icon={<CalendarOutlined/>}>
                            <Link to={APP_CALENDAR}>Kalendarz </Link>
                        </Menu.Item>

                        <Menu.Item key="3" icon={<FormOutlined/>}>
                            <Link to={APP_DATA}>Moje dane</Link>
                        </Menu.Item>

                    </Menu.ItemGroup>

                    <Menu.ItemGroup>

                        <Menu.Item key="4" icon={<PoweroffOutlined/>}>
                            Wyloguj się
                        </Menu.Item>

                        <LogoutModal {...logoutModalProps}/>

                    </Menu.ItemGroup>

                </Menu>
            </Sider>

            <Layout>
                <Header>
                    <h1 style={{color: '#fff'}}>
                        <Route path={APP_CALENDAR}><HeaderText text={'Kalendarz'}/></Route>
                        <Route path={APP_RESERVATIONS}><HeaderText text={'Moje rezerwacje'}/></Route>
                        <Route path={APP_DATA}>Moje dane</Route>
                    </h1>
                </Header>

                <Content style={{overflow: "scroll", overflowX: "hidden"}}>
                    <Route path={APP_CALENDAR} component={CalendarPage}/>
                    <Route path={APP_RESERVATIONS} component={MyReservationsPage}/>
                    <Route path={APP_DATA} component={MyDataPage}/>
                </Content>

            </Layout>
        </Layout>
    )
}
