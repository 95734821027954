import React, {useState} from 'react';
import {getApi} from "./api";
import {Button, Form, Input, Layout} from 'antd';
import logo from './static/logo_login.png'
import {AUTH_LOGIN} from "./urls";
import {Link} from "react-router-dom";
import {errorMessageToDisplay} from "./copy";


export function PasswordResetRequestPage() {
    const [requestSuccessful, setRequestSuccessful] = useState(false);
    const [requestProcessing, setRequestProcessing] = useState(false);
    const [form] = Form.useForm();

    function onFinish(values) {
        setRequestProcessing(true);

        const email = values.email;
        return getApi().requestPasswordReset(email).then(function () {
            setRequestSuccessful(true);
        }).catch(function (errors) {
            if (errors.new_password && errors.new_password.length > 0) {
                const passErr = errors.new_password.map(errorMessageToDisplay);

                form.setFields([
                        {name: 'password', errors: passErr}
                    ]
                );
            }
        }).finally(function () {
            setRequestProcessing(false);
        });
    }

    const inputProps = {
        name: 'email',
        rules: [{required: true}],
    }

    console.log('render: PasswordResetRequestPage')

    let view;

    if (requestSuccessful) {
        view = (
            <>
                <h1>Link został wysłany</h1>
                <p>
                    Wysłaliśmy na podaną skrzynkę link do resetu hasła. Email powinien dotrzeć w ciągu kilku minut.
                    Jeżeli nie dostałeś emaila, sprawdź forder Spam albo skontaktuj się z administratorem.
                </p>
                <Link to={AUTH_LOGIN}>Powrót do strony głównej</Link>
            </>
        );
    } else {
        view = (<>
            <Form form={form} onFinish={onFinish} className="login-form">

                <p>
                    Wpisz poniżej email podany przy rejestracji konta. Wyślemy Ci wiadomość z dalszymi instrukcjami.
                </p>

                <Form.Item {...inputProps}>
                    <Input placeholder="bogdan86@gmail.com"/>
                </Form.Item>

                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                        loading={requestProcessing}
                    >
                        Resetuj hasło
                    </Button>
                    <Link to={AUTH_LOGIN}>Powrót do strony głównej</Link>
                </Form.Item>
            </Form>
        </>);
    }

    return (
        <Layout>
            <div style={{width: 300, margin: 'auto', textAlign: 'center'}}>
                <img src={logo} style={{width: '100%', padding: "25px 50px"}} alt="Logo"/>
                {view}
            </div>
        </Layout>
    );
}