import { Button, Calendar, Grid, message, Popover, Select } from 'antd';
import moment from 'moment';
import 'moment/locale/pl';
import { isBeforeDate, isPastDate, isSameDate, LOCALE_DATE_FORMAT, toIso } from "./time";
import { useContext, useState } from 'react';
import { ReservationModal } from './ReservationModal';
import { ReservationCancelModal } from "./ReservationCancelModal";
import { CloseOutlined } from '@ant-design/icons';
import { Context } from "./context/Store";
import { isDateDisabled } from "./reservation";


const { useBreakpoint } = Grid;

moment.locale('pl');


function findInfoForDate(date, processed) {
    return processed.find(element => isSameDate(element.date, date));
}


function processReservations(reservations) {

    let processed = [];
    let current, start, end, first, last;

    reservations.forEach(function (r) {
        start = moment(r['start_date']);
        end = moment(r['end_date']);

        if (isBeforeDate(end, start)) {
            // This shouldn't happen, but handling anyway
            end = moment(r['start_date']);
            start = moment(r['end_date']);
        }

        current = start.clone()

        while (true) {
            first = isSameDate(current, start);
            last = isSameDate(current, end);

            processed.push({
                date: current.clone(),
                user: r['user']['username'],
                start: start,
                end: end,
                first: first,
                last: last,
                obj: r
            })

            if (last) {
                break;
            }

            current.add(1, 'days');
        }
    })

    return processed;

}


export function ReservationDateCellContent({ date, dateInfo, reservationStatus, small, onCancelClick, onEditClick }) {
    let className = [], text = [], isOwn, showCancel;
    let popoverProps = {}, popoverContent, divText;
    const [popoverVisible, setPopoverVisible] = useState(false);
    // eslint-disable-next-line
    const [state, dispatch] = useContext(Context);
    const [isDisabled, disabledReason] = isDateDisabled(date, [], reservationStatus);

    function onCancelButtonClick() {
        setPopoverVisible(false);
        onCancelClick(dateInfo);
    }

    function onEditButtonClick() {
        setPopoverVisible(false);
        onEditClick(dateInfo);
    }

    function makePopoverTitle(text) {
        return (
            <div style={{ display: 'flex', cursor: 'pointer' }}>
                <span>{text}</span>
                <span style={{ marginLeft: 'auto' }}><CloseOutlined onClick={() => setPopoverVisible(false)} /></span>
            </div>
        )
    }

    function makeText() {
        text = [];
        let participantNo;

        if (isOwn) {
            participantNo = dateInfo.obj.children.length;
        } else {
            participantNo = dateInfo.obj.children.filter(c => c.approved).length;
        }
        text.push(<p style={{ marginBottom: '3px' }}>{dateInfo.user}</p>);
        if (participantNo) {
            text.push(<p>{`+ ${participantNo}os`}</p>);
        }
    }

    if (dateInfo) {
        isOwn = (dateInfo.user === state.user.username) || (dateInfo.obj.children.find(c => c.user && c.user.username === state.user.username && c.approved));
        showCancel = isOwn & !isPastDate(dateInfo.end);
        className.push('reserved');

        if (isOwn) {
            className.push('my');
        }

        if (!small) {
            if (dateInfo.first) {
                className.push('first');
                makeText();
            }

            if (dateInfo.last) {
                className.push('last');
            }

        }

        if (dateInfo.date.day() === 1) {
            makeText();
        }

        let participants = dateInfo.user;
        let usernames
        if (isOwn) {
            usernames = dateInfo.obj.children.filter(c => c.user).map(c => c.user.username)
        } else {
            usernames = dateInfo.obj.children.filter(c => c.approved && c.user).map(c => c.user.username)
        }

        if (usernames.length) {
            participants += ', '
            participants += usernames.join(', ');
        }

        const anonNo = dateInfo.obj.children.filter(c => c.approved && !c.user).length;
        participants += anonNo ? ` + ${anonNo}os` : '';

        popoverContent = (
            <div>
                <p>Od: {dateInfo.start.format(LOCALE_DATE_FORMAT)} godz. 16:00</p>
                <p>Do: {moment(dateInfo.end).add(1, 'day').format(LOCALE_DATE_FORMAT)} godz. 16:00</p>
                <p>Uczestnicy: {participants}</p>
                <div style={showCancel ? {} : { display: 'none' }}>
                    <Button onClick={onCancelButtonClick} type='link' style={{ padding: 0, marginRight: '8px' }}>
                        Odwołaj
                    </Button>
                    <Button onClick={onEditButtonClick} type='link' style={{ padding: 0 }}>
                        Edytuj
                    </Button>
                </div>
            </div>
        );

        popoverProps = {
            title: makePopoverTitle('Zarezerwował: ' + dateInfo.user),
            content: popoverContent,
            onVisibleChange: setPopoverVisible
        };

        divText = small ? dateInfo.date.format('D') : text;

    } else if (isDisabled) {
        popoverProps = {
            title: makePopoverTitle('Termin niedostępny'),
            content: disabledReason,
            onVisibleChange: () => setPopoverVisible(false),
            onClick: () => setPopoverVisible(true)
        };

        divText = '';
    } else {
        return (<div />);
    }

    return (
        <Popover visible={popoverVisible} style={{ maxWidth: '100px' }}{...popoverProps}>
            <div className={className.join(' ')} style={small ? { position: 'absolute', top: 0 } : {}}>
                <div style={!small ? { padding: '8px' } : {}}>{divText}</div>
            </div>
        </Popover>
    );

}


export function ReservationCalendar({
    spots,
    reservations,
    reservationStatus,
    selectedSpot,
    defaultMonth,
    onSpotChange,
    onReservationCreationOrEditSubmit,
    onReservationCancelSubmit
}) {

    const [modal, setModal] = useState();
    const [selectedDateInfo, setSelectedDateInfo] = useState({});
    const MODAL_EDIT_OR_CREATE = 'modal_edit_or_create';
    const MODAL_CANCEL = 'modal_cancel';
    const screens = useBreakpoint();

    reservationStatus = reservationStatus || {
        'range': {
            'min': moment(),
            'max': moment()
        }
    };

    function isSmall() {
        return !screens.md;
    }

    function dateCellRender(processedReservations) {
        return function render(value) {
            const dateInfo = findInfoForDate(value, processedReservations);
            const props = {
                date: value,
                dateInfo: dateInfo,
                reservationStatus: reservationStatus,
                small: isSmall(),
                onCancelClick: function (dateInfo) {
                    setSelectedDateInfo({ date: dateInfo.date, info: dateInfo });
                    setModal(MODAL_CANCEL);
                },
                onEditClick: function (dateInfo) {
                    setSelectedDateInfo({ date: dateInfo.date, info: dateInfo });
                    setModal(MODAL_EDIT_OR_CREATE);
                },
            };
            return (
                <ReservationDateCellContent {...props} />
            );
        }
    }

    function makeHeaderRender(selectedSpot, spots, onSpotChange, defaultMonth) {

        const makeSpotOptions = function (spots) {
            return spots.map(({ name, id }) => ({ label: name, value: id }));
        }

        const makeMonthOptions = function () {
            return moment.months().map((name, i) => ({ label: name, value: i }));
        }

        const headerRender = function ({ value, type, onChange, onTypeChange }) {

            const onMonthChange = function (month) {
                let monthMoment = moment([moment().year(), month]);
                monthMoment.hack = true;
                onChange(monthMoment);
            }

            return (
                <div className="ant-picker-calendar-header">
                    <Select
                        className="ant-picker-calendar-year-select"
                        options={makeSpotOptions(spots)}
                        onChange={onSpotChange}
                        value={selectedSpot}
                    />
                    <Select
                        className="ant-picker-calendar-month-select"
                        options={makeMonthOptions()}
                        onChange={onMonthChange}
                        defaultValue={defaultMonth}
                        value={value.month()}
                    />
                </div>
            );
        }

        return headerRender;

    }

    function onSelect(date) {
        // Hack to determine month change vs. clicking on a date
        if (date.hack) {
            return;
        }

        const dateInfo = findInfoForDate(date, processedReservations);
        setSelectedDateInfo({ date: date, info: dateInfo });

        if (dateInfo) {
            return;
        }

        setModal(MODAL_EDIT_OR_CREATE);
    }

    function onPanelChange(date) {
        message.success('Zmieniono miesiąc na ' + date.format('MMMM'));
        date.hack = true;
    }

    function onReservationModalOk([startDate, endDate], spotId, children) {
        return onReservationCreationOrEditSubmit(
            spotId,
            toIso(startDate),
            toIso(endDate),
            children,
            selectedDateInfo.info ? selectedDateInfo.info.obj : undefined
        ).then(function () {
            setModal(undefined);
        })
    }

    function onCancelModalOk(reservation) {
        return onReservationCancelSubmit(
            reservation,
        ).then(function () {
            setModal(undefined);
        })
    }

    const processedReservations = processReservations((reservations));
    const calendarProps = {
        dateCellRender: dateCellRender(processedReservations),
        headerRender: makeHeaderRender(selectedSpot, spots, onSpotChange, defaultMonth),
        onSelect: onSelect,
        mode: 'month',
        fullscreen: !isSmall(),
        format: LOCALE_DATE_FORMAT,
        disabledDate: (date) => isDateDisabled(date, [], reservationStatus)[0],
        onPanelChange: onPanelChange
    }
    const reservationModalProps = {
        visible: Boolean(modal === MODAL_EDIT_OR_CREATE),
        onOkCallback: onReservationModalOk,
        onCancelCallback: () => setModal(undefined),
        onSpotChange: onSpotChange,
        startDate: selectedDateInfo.info ? moment(selectedDateInfo.info.obj.start_date) : selectedDateInfo.date,
        spot: spots.find(element => element.id === selectedSpot),
        spots: spots,
        reservation: selectedDateInfo.info ? selectedDateInfo.info.obj : undefined,
        spotReservations: reservations,
        reservationStatus: reservationStatus
    }
    const cancelModalProps = {
        visible: Boolean(modal === MODAL_CANCEL),
        onOkCallback: onCancelModalOk,
        onCancelCallback: () => setModal(undefined),
        spot: spots.find(element => element.id === selectedSpot),
        reservation: selectedDateInfo.info ? selectedDateInfo.info.obj : undefined
    }

    console.log('render: ReservationCalendar')

    return (
        <>
            <ReservationModal {...reservationModalProps} />
            <ReservationCancelModal {...cancelModalProps} />
            <Calendar {...calendarProps} />
        </>

    );

}
