import {Button, Descriptions, Layout} from "antd";
import {useContext, useEffect, useState} from "react";
import {Context} from "./context/Store";
import {getApi} from "./api";

export function MyDataPage() {
    // eslint-disable-next-line
    const [state, dispatch] = useContext(Context);
    const [license, setLicense] = useState();

    useEffect(function () {
        getApi().fetchLicense().then(function (license) {
            setLicense(license);
        })
    }, []);

    function downloadLicense() {
        const newWindow = window.open('', '_blank');

        getApi().getPdfLink(license).then(function (pdfUrl) {
            newWindow.location = pdfUrl;
        });
    }

    return (
        <Layout.Content>
            <Descriptions title={'Użytkownik: ' + state.user['username']} layout='horizontal' column={1}>
                <Descriptions.Item label='Imię'>{state.user['first_name']}</Descriptions.Item>
                <Descriptions.Item label='Nazwisko'>{state.user['last_name']}</Descriptions.Item>
                <Descriptions.Item label='Email'>{state.user['email']}</Descriptions.Item>
                <Descriptions.Item label='Nr karty'>{state.user['card_number']}</Descriptions.Item>

                <Descriptions.Item label='Licencja'>
                    {license ? license.no : ''}
                    <Button style={{marginLeft: '8px'}} size='small' onClick={downloadLicense}>
                        Pobierz
                    </Button>
                </Descriptions.Item>

            </Descriptions>
        </Layout.Content>
    );
}