import {Modal} from "antd";
import 'moment/locale/pl';


export function LogoutModal({visible, onOkCallback, onCancelCallback}) {
    const modalProps = {
        visible: visible,
        title: 'Wyloguj się',
        onOk: onOkCallback,
        onCancel: onCancelCallback,
        okText: 'Wyloguj się',
        cancelText: 'Anuluj'
    };

    console.log('render: LogoutModal')

    return (
        <Modal {...modalProps}>
            <p>Czy na pewno chcesz się wylogować?</p>
        </Modal>
    )
}