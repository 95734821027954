import { Alert, DatePicker, Form, InputNumber, Modal, Select, Button } from "antd";
import { MinusCircleOutlined } from '@ant-design/icons';
import moment from "moment";
import 'moment/locale/pl';
import { useContext, useEffect, useState } from 'react';
import { Context } from "./context/Store";
import { getLength, isDateDisabled } from "./reservation";
import { LOCALE_DATE_FORMAT } from "./time";
import { toIso } from "./time";
import { isClub } from "./user";

moment.locale('pl');


function ReservationAlert({ visible, text }) {
    if (!visible) {
        return (<div />);
    }

    return (<Alert message={text} type="error" />);
}

export function ReservationModal({
    visible,
    onOkCallback,
    onCancelCallback,
    startDate,
    onSpotChange,
    spot,
    spots,
    reservation,
    spotReservations,
    reservationStatus,
}) {
    const [form] = Form.useForm();
    const [error, setError] = useState();
    const [buttonDisabled, setButtonDisabled] = useState(false);
    // eslint-disable-next-line
    const [state, dispatch] = useContext(Context);
    const SPOT_NAME = 'spot';
    const START_NAME = 'startDate';
    const LENGTH_NAME = 'length';
    const CHILDREN_NAME = 'children';
    let initialDate = moment(startDate);
    let initialLength = reservation ? getLength(reservation) : 1;
    let innitialChildren = reservation ? reservation.children.map(c => c.user?.id || null) : [];

    // Re-apply initialValue
    useEffect(function () {
        console.log('useEffect: ReservationModal')
        form.resetFields()
        setError(undefined);
    }, [startDate, form, visible, spot]);

    function onOk() {
        let startDate, endDate, errors, success = true, spotId, children;

        errors = form.getFieldsError([START_NAME, LENGTH_NAME]);

        errors.forEach(function (element) {
            if (element.errors.length) {
                success = false;
            }
        })

        if (!success) {
            return;
        }

        spotId = form.getFieldValue(SPOT_NAME);
        startDate = toIso(form.getFieldValue(START_NAME));
        endDate = toIso(moment(startDate).add(form.getFieldValue(LENGTH_NAME) - 1, 'days'));

        try {
            const childrenFormValue = form.getFieldValue(CHILDREN_NAME);
            children = childrenFormValue.map(ch => {
                const ret = { start_date: toIso(startDate), end_date: toIso(endDate) };

                if (ch) {
                    ret.user = { id: ch };
                }

                return ret;
            })
        } catch {
            children = [];
        }


        onOkCallback([startDate, endDate], spotId, children).catch(function (errorMessage) {
            setError(errorMessage);
        });
    }

    function getTitle() {
        if (reservation) {
            return 'Edycja rezerwacji: ' + (spot ? spot.name : '');
        }

        return 'Rezerwacja: ' + (spot ? spot.name : '');
    }

    const formLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    const modalProps = {
        visible: visible,
        title: getTitle(),
        onOk: onOk,
        onCancel: onCancelCallback,
        okText: reservation ? 'Zmień' : 'Rezerwuj',
        cancelText: 'Anuluj',
        okButtonProps: { danger: Boolean(reservation), disabled: buttonDisabled }
    };
    const formSpotItemProps = {
        name: SPOT_NAME,
        label: 'Stanowisko',
        initialValue: spot ? spot.id : undefined,
        rules: [{ required: true, message: 'Proszę wybrać stanowisko' }]
    };
    const formStartDateItemProps = {
        name: START_NAME,
        label: 'Zaczynam dnia',
        initialValue: initialDate,
        rules: [{ required: true, message: 'Proszę wybrać datę' }]
    };
    const formLengthItemProps = {
        name: LENGTH_NAME,
        label: 'Siedzę dni',
        initialValue: initialLength,
        rules: [
            { required: true, message: 'Proszę wybrać liczbę dni' },
            { type: 'number', message: 'Proszę podać liczbe' },
        ]
    };
    const datePickerProps = {
        format: LOCALE_DATE_FORMAT,
        disabledDate: (date) => isDateDisabled(date, spotReservations, reservationStatus, reservation)[0],
        showTime: false,
        // Avoids virtual keyboard on mobile
        inputReadOnly: true
    };
    const inputNumberProps = {
        keyboard: false,
        min: 1,
        max: 7
    };
    const alertProps = {
        visible: Boolean(error),
        text: error
    };

    console.log('render: ReservationModal')

    const spotPickerProps = {
        onChange: onSpotChange,
        options: spots.map(({ name, id }) => ({ label: name, value: id }))
    };

    const childPickerProps = {
        options: state.users.map(({ username, id }) => ({ label: username, value: id })).concat({ label: 'Z mojej puli', value: null }),
        defaultValue: null,
        showSearch: true,
        placeholder: 'Wyszukaj osobę',
        filterOption: (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
    };

    function onFieldsChange(changed, all) {
        if (typeof (state.daysAvailable) !== 'number') {
            return;
        }

        const childrenToPayFor = (form.getFieldValue(CHILDREN_NAME) || []).filter(c => !c);
        const required = form.getFieldValue(LENGTH_NAME) * (childrenToPayFor.length ? childrenToPayFor.length + 1 : 1);
        if (state.daysAvailable < required) {
            let msg = `Ta rezerwacj wymaga ${required} dni a pozostało Ci ${state.daysAvailable}. Skróć czas zasiadki`;
            if (childrenToPayFor.length) {
                msg += ' albo usuń osoby towarzyszące';
            }
            msg += '.';
            form.setFields([{ name: LENGTH_NAME, errors: [msg] }]);
        } else {
            form.setFields([{ name: LENGTH_NAME, errors: [] }]);
        }

        setButtonDisabled(form.getFieldsError().some((item) => item.errors.length > 0));
    }

    return (
        <Modal {...modalProps}>
            <Form form={form} onFieldsChange={onFieldsChange} {...formLayout}>
                <Form.Item {...formSpotItemProps}>
                    <Select {...spotPickerProps} />
                </Form.Item>
                <Form.Item {...formStartDateItemProps}>
                    <DatePicker {...datePickerProps} />
                </Form.Item>
                <Form.Item {...formLengthItemProps}>
                    <InputNumber {...inputNumberProps} />
                </Form.Item>
                {!isClub(state.user) &&
                    <Form.List name={CHILDREN_NAME} initialValue={innitialChildren}>
                        {(fields, { add, remove }, { errors }) => (
                            <>
                                {fields.map((field, index) => (
                                    <div key={field.key} style={{ display: 'flex' }}>
                                        <Form.Item
                                            label={`Dodatkowa osoba ${index + 1}`}
                                            required={false}
                                            style={{ flex: 1 }}
                                            {...field}
                                        >
                                            <Select {...childPickerProps} style={{ width: '90%', marginRight: '10px' }} />
                                        </Form.Item>
                                        <Button style={{ borderWidth: 0, marginLeft: '-25px', cursor: 'pointer', width: 0 }} onClick={() => {
                                            remove(field.name);
                                            onFieldsChange();
                                        }}>
                                            <MinusCircleOutlined />
                                        </Button>
                                    </div>
                                ))}
                                {/*<Form.Item label="Dodaj osobę">*/}
                                {/*    <Button*/}
                                {/*        type="dashed"*/}
                                {/*        onClick={() => {*/}
                                {/*            add();*/}
                                {/*            onFieldsChange();*/}
                                {/*        }}*/}
                                {/*        icon={<PlusOutlined />}*/}
                                {/*    >*/}

                                {/*    </Button>*/}
                                {/*    <Form.ErrorList errors={errors} />*/}
                                {/*</Form.Item>*/}
                            </>
                        )}
                    </Form.List>
                }
                <ReservationAlert {...alertProps} />
            </Form>
        </Modal>
    )
}
