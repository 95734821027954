import React, {useContext, useEffect, useState, useCallback} from 'react';
import {message, Spin} from 'antd';
import {ReservationCalendar} from "./ReservationCalendar";
import {getApi} from "./api";
import {getCurrentBrzezonkoMonth, getFirstDayOfTheMonth, getLastDayOfTheMonth} from "./time";
import {Context} from "./context/Store";
import {errorMessageToDisplay} from "./copy";


export function CalendarPage() {
    const [loading, setLoading] = useState(true);
    const [masterReservations, setMasterReservations] = useState([]);
    const [reservationStatus, setReservationStatus] = useState();
    const [selectedSpot, setSelectedSpot] = useState();
    // eslint-disable-next-line
    const [state, dispatch] = useContext(Context);

    function loadReservations(spot) {
        // Cover for calendar showing adjacent months
        const startDayLow = getFirstDayOfTheMonth(0).subtract(14, 'days');
        const startDayHigh = getLastDayOfTheMonth(11).add(14, 'days');

        return getApi().fetchReservationsForSpot(spot, startDayLow, startDayHigh, true).then(function (data) {
            setMasterReservations(data.filter(r => !!r.user));
            return data;
        })
    }

    const loadReservationStatus = useCallback(() => {
        return getApi().fetchReservationStatus().then(function (status) {
            setReservationStatus(status);
            dispatch({ type: 'SET_DAYS_AVAILABLE', payload: status.days_available })
        });
    }, [dispatch])

    function onReservationCreationOrEditSubmit(spotId, startDate, endDate, children, oldReservation) {
        setLoading(true);
        let promise;
        let msg;

        if (oldReservation) {
            msg = 'Rezerwacja zmieniona pomyślnie';
            promise = getApi().editReservation(oldReservation.id, spotId, startDate, endDate, children);
        } else {
            msg = 'Stanowisko zarezerwowne pomyślnie';
            promise = getApi().createReservation(spotId, startDate, endDate, children);
        }

        return promise.then(function () {
            message.success(msg);
            return loadReservations(selectedSpot).then(loadReservationStatus);
        }).catch(function (code) {
            return Promise.reject(errorMessageToDisplay(code));
        }).finally(function () {
            setLoading(false);
        })
    }

    function onReservationCancelSubmit(reservation) {
        return loadReservations(selectedSpot).then(function () {
            message.success('Rezerwacja odwołana pomyślnie');
            return loadReservationStatus();
        });
    }

    function onSpotChange(value) {
        setLoading(true);
        setSelectedSpot(value);

        return loadReservations(value).then(function () {
            setLoading(false);
        })
    }

    useEffect(function () {
        console.log('useEffect: CalendarPage')

        if (state.spots.length === 0) {
            return;
        }

        const spot = state.spots[0].id;
        setSelectedSpot(spot);

        Promise.all([loadReservations(spot), loadReservationStatus()]).then(function () {
            setLoading(false);
        });

    }, [state.spots, loadReservationStatus])

    const calendarProps = {
        spots: state.spots,
        reservations: masterReservations,
        reservationStatus: reservationStatus,
        selectedSpot: selectedSpot,
        defaultMonth: getCurrentBrzezonkoMonth(),
        onSpotChange: onSpotChange,
        onReservationCreationOrEditSubmit: onReservationCreationOrEditSubmit,
        onReservationCancelSubmit: onReservationCancelSubmit
    };

    console.log('render: CalendarPage')

    return (
        <Spin size="large" spinning={loading}>
            <ReservationCalendar {...calendarProps}/>
        </Spin>
    );
}
