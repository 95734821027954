export const KEYS = {
    AUTH_TOKEN: 'authToken'
};

export function set(key, token) {
    localStorage.setItem(key, token);
}

export function get(key) {
    return localStorage.getItem(key);
}