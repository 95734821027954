import axios from 'axios';
import moment from "moment";


const API_PREFIX = '/api/v1';

class Api {

    constructor(baseUrl, getToken, setToken, onUnauthorized) {

        this.instance = axios.create({
            baseURL: baseUrl,
            xsrfCookieName: 'csrftoken',
            xsrfHeaderName: 'X-CSRFTOKEN'
        })

        this.instance.interceptors.response.use(function (response) {
            return response
        }, function (error) {
            if (error.response.status === 401) {
                onUnauthorized();
            }
            return Promise.reject(error)
        })

        this.getToken = getToken;
        this.setToken = setToken;
        this.baseUrl = baseUrl;

    }

    makeAuthHeaders() {
        return { 'Authorization': 'Token ' + this.getToken() };
    }

    register(username, password, email, card_number, first_name, last_name, agreed_terms) {
        return this.instance.post(
            '/api/v1/users/',
            {
                username: username,
                password: password,
                email: email,
                card_number: card_number,
                first_name: first_name,
                last_name: last_name,
                agreed_terms: agreed_terms
            }
        ).catch(function (error) {
            const data = error.response.data;
            let errors = {};
            let password = data['details'] ? data['details']['password'] : undefined;
            let email = data['details'] ? data['details']['email'] : undefined;
            let username = data['details'] ? data['details']['username'] : undefined;

            if (password) {
                errors['password'] = password.map(e => e.code);
            }

            if (email) {
                errors['email'] = GENERAL_ERROR;
            }

            if (username) {
                errors['username'] = GENERAL_ERROR;
            }

            return Promise.reject(errors);
        });
    }

    login(username, password) {
        let self = this;

        return this.instance.post(
            '/auth/login/',
            { username: username, password: password }
        ).then(function (response) {
            const token = response.data['auth_token']
            self.setToken(token);

        }).catch(function (error) {
            const data = error.response.data;
            let code;

            if (!data['details'] || !data['details']['non_field_errors']) {
                code = GENERAL_ERROR;
            } else if (data['details']['non_field_errors'].find(element => element['code'] === BAD_LOGIN_CREDENTIALS)) {
                code = BAD_LOGIN_CREDENTIALS;
            } else {
                code = GENERAL_ERROR;
            }

            return Promise.reject(code);

        });
    }

    logout() {
        return this.instance.post(
            '/auth/logout/',
            {},
            { headers: this.makeAuthHeaders() }
        ).then(function (response) {
            return response.data;
        })
    }

    fetchSpots() {
        return this.instance.get(
            API_PREFIX + '/spots/',
            { headers: this.makeAuthHeaders() }
        ).then(function (response) {
            return response.data;
        })
    }

    fetchReservationsForSpot(spotId, startDateLow, startDateHigh, isMaster) {
        let params = {
            'spot_id': spotId,
            'start_date__gte': formatDate(startDateLow),
            'start_date__lte': formatDate(startDateHigh)
        };

        if (isMaster) {
            params['is_master'] = true;
        }

        return this.instance.get(
            API_PREFIX + '/reservations/',
            {
                headers: this.makeAuthHeaders(),
                params: params,
            }
        ).then(function (response) {
            return response.data;
        })
    }

    fetchReservationsForUser(userId) {
        return this.instance.get(
            API_PREFIX + '/reservations/',
            {
                headers: this.makeAuthHeaders(),
                params: {
                    'user_id': userId
                }
            }
        ).then(function (response) {
            return response.data;
        })
    }

    fetchUsers() {
        return this.instance.get(
            API_PREFIX + '/users/',
            {
                headers: this.makeAuthHeaders(),
            }
        ).then(function (response) {
            return response.data;
        })
    }

    fetchOwnProfile() {
        return this.instance.get(
            API_PREFIX + '/users/me/',
            {
                headers: this.makeAuthHeaders()
            }
        ).then(function (response) {
            return response.data;
        })
    }

    createReservation(spotId, startDate, endDate, children) {
        return this.instance.post(
            API_PREFIX + '/reservations/',
            {
                'spot_id': spotId,
                'start_date': startDate,
                'end_date': endDate,
                'children': children,
            },
            {
                headers: this.makeAuthHeaders()
            }
        ).then(function (response) {
            return response.data;
        }).catch(function (error) {
            const code = error.response.data.code ? error.response.data.code : GENERAL_ERROR;
            return Promise.reject(code);
        })
    }

    editReservation(id, spotId, startDate, endDate, children) {
        return this.instance.put(
            API_PREFIX + '/reservations/' + id + '/',
            {
                'spot_id': spotId,
                'start_date': startDate,
                'end_date': endDate,
                'children': children,
            },
            {
                headers: this.makeAuthHeaders()
            }
        ).then(function (response) {
            return response.data;
        }).catch(function (error) {
            const code = error.response.data.code ? error.response.data.code : GENERAL_ERROR;
            return Promise.reject(code);
        })
    }

    cancelReservation(reservationId) {
        return this.instance.delete(
            API_PREFIX + '/reservations/' + reservationId + '/',
            {
                headers: this.makeAuthHeaders()
            }
        );
    }

    approveReservation(reservationId) {
        return this.instance.post(
            API_PREFIX + '/reservations/' + reservationId + '/approve/',
            null,
            {
                headers: this.makeAuthHeaders()
            }
        ).catch(function (error) {
            const code = error.response.data.code ? error.response.data.code : GENERAL_ERROR;
            return Promise.reject(code);
        });
    }

    fetchReservationStatus() {
        return this.instance.get(
            API_PREFIX + '/reservations/status/',
            { headers: this.makeAuthHeaders() }
        ).then(function (response) {
            let data = response.data;

            data.range.min = data.range.min ? moment(data.range.min) : undefined;
            data.range.max = data.range.max ? moment(data.range.max) : undefined;

            return Promise.resolve(data);
        })
    }

    fetchLicense() {
        return this.instance.get(
            API_PREFIX + '/licenses/',
            { headers: this.makeAuthHeaders() }
        ).then(function (response) {
            return response.data.length > 0 ? response.data[0] : undefined;
        })
    }

    getPdfLink(license) {
        const baseUrl = this.baseUrl;
        return this.instance.post(
            API_PREFIX + '/licenses/' + license.id + '/view_token/',
            {},
            { headers: this.makeAuthHeaders() }
        ).then(function (response) {
            return baseUrl + API_PREFIX + '/licenses/' + license.id + '/pdf/?token=' + response.data.id;
        })
    }

    requestPasswordReset(email) {
        return this.instance.post(
            API_PREFIX + '/users/reset_password/',
            { email: email },
        ).catch(function (error) {
            const data = error.response.data;
            let errors = {};
            let new_password = data['details'] ? data['details']['new_password'] : undefined;

            if (new_password) {
                errors['new_password'] = GENERAL_ERROR;
            }

            return Promise.reject(errors);
        })
    }

    confirmPasswordReset(uid, token, newPassword) {
        return this.instance.post(
            API_PREFIX + '/users/reset_password_confirm/',
            {
                uid: uid,
                token: token,
                new_password: newPassword
            },
        ).catch(function (error) {
            const data = error.response.data;
            let errors = {};
            let password = data['details'] ? data['details']['new_password'] : undefined;
            let token = data['details'] ? data['details']['token'] : undefined;

            if (password) {
                errors['new_password'] = password.map(e => e.code);
            }

            if (token) {
                errors['token'] = GENERAL_ERROR;
            }

            return Promise.reject(errors);
        })
    }
}


function formatDate(momentDate) {
    return momentDate.format('YYYY-MM-DD');
}


let currentInstance;


export function configureApi(baseUrl, getToken, setToken, onUnauthorized) {
    console.log('Configure API')
    currentInstance = new Api(baseUrl, getToken, setToken, onUnauthorized);
}


export function getApi() {
    return currentInstance;
}


export const PASSWORD_TOO_SHORT = 'password_too_short';
export const PASSWORD_TOO_COMMON = 'password_too_common';
export const PASSWORD_ENTIRELY_NUMERIC = 'password_entirely_numeric';
export const PASSWORD_TOO_SIMILAR = 'password_too_similar';
export const BAD_LOGIN_CREDENTIALS = 'invalid_credentials';
export const GENERAL_ERROR = 'general_error';
export const TOO_MANY_RESERVATIONS = 'too_many_reservations';
export const TOO_LONG_RESERVATION = 'too_long_reservation';
export const OVERLAP_RESERVATION = 'overlap_reservation';
export const NOT_ENOUGH_DAYS_AVAILABLE = "not_enough_days_available";


export const SEASON_NOT_STARTED = 'season_not_started';