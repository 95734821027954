import React, {useState} from 'react';
import {getApi} from "./api";
import {Button, Form, Input, Layout} from 'antd';
import logo from './static/logo_login.png'
import {AUTH_LOGIN, AUTH_PASSWORD_RESET_REQUEST} from "./urls";
import {Link} from "react-router-dom";
import {errorMessageToDisplay} from "./copy";


export function PasswordResetConfirmPage({...props}) {
    const [requestSuccessful, setRequestSuccessful] = useState(false);
    const [requestProcessing, setRequestProcessing] = useState(false);
    const [tokenExpired, setTokenExpired] = useState(false);
    const [form] = Form.useForm();

    function onFinish(values) {
        setRequestProcessing(true);

        let query = new URLSearchParams(props.location.search);
        const uid = query.get('uid');
        const token = query.get('token');
        const password = values.new_password;

        return getApi().confirmPasswordReset(uid, token, password).then(function () {
            setRequestSuccessful(true);
        }).catch(function (errors) {
            if (errors.new_password && errors.new_password.length > 0) {
                form.setFields([
                        {name: 'new_password', errors: errors.new_password.map(errorMessageToDisplay)}
                    ]
                );
            }

            if (errors.token) {
                setTokenExpired(true);
            }

        }).finally(function () {
            setRequestProcessing(false);
        });
    }

    const inputProps = {
        name: 'new_password',
        rules: [{required: true}],
    }

    console.log('render: PasswordResetConfirmPage')

    let view;

    if (requestSuccessful) {
        view = (
            <div>
                <h1>Hasło zmienione</h1>
                <p>
                    Od teraz możesz logować się do systemu używając nowego hasła.
                </p>
                <Link to={AUTH_LOGIN}>Powrót do strony głównej</Link>
            </div>
        );
    } else if (tokenExpired) {
        view = (
            <div>
                <h1>Błąd formularza</h1>
                <p>
                    Ten formularz zmiany hasła został już wcześniej wykorzystany lub minęła jego data ważności.
                    Musisz zresetować hasło jeszcze raz.
                </p>
                <Link to={AUTH_PASSWORD_RESET_REQUEST}>Przejdź do resetu hasła</Link>
            </div>
        );
    } else {
        view = (<>
            <Form form={form} onFinish={onFinish} className="login-form" layout="vertical">

                <p>
                    Podaj nowe hasło jakim chcesz się od teraz logować.
                </p>

                <Form.Item {...inputProps}>
                    <Input placeholder="**********" type="password"/>
                </Form.Item>

                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                        loading={requestProcessing}
                    >
                        Zmień hasło
                    </Button>
                    <Link to={AUTH_LOGIN}>Powrót do strony głównej</Link>
                </Form.Item>
            </Form>
        </>);
    }

    return (
        <Layout>
            <div style={{width: 300, margin: 'auto', textAlign: 'center'}}>
                <img src={logo} style={{width: '100%', padding: "25px 50px"}} alt="Logo"/>
                {view}
            </div>
        </Layout>
    );
}