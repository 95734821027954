import React, { useCallback, useContext, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { getApi } from "./api";
import { APP_RESERVATIONS, AUTH_PASSWORD_RESET_REQUEST, AUTH_REGISTER } from "./urls";
import { Button, Form, Input, Layout } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import logo from './static/logo_login.png'
import { Context } from "./context/Store";
import LogRocket from 'logrocket';


export function LoginPage() {
    let history = useHistory();
    const [form] = Form.useForm();
    // eslint-disable-next-line
    const [state, dispatch] = useContext(Context);

    const navigateToApp = useCallback(function () {
        history.push(APP_RESERVATIONS);
    }, [history]);

    useEffect(function () {
        if (state.user) {
            navigateToApp();
        }
    }, [state.user, navigateToApp])

    function onFinish(values) {
        const username = values.username;
        const password = values.password;

        return getApi().login(username, password).then(function () {
            getApi().fetchOwnProfile().then(function (user) {
                dispatch({ type: 'SET_USER', payload: user });

                LogRocket.identify(user.id, {
                    name: user.username,
                    email: user.email
                });

                return Promise.all([
                    getApi().fetchSpots().then(function (spots) {
                        dispatch({ type: 'SET_SPOTS', payload: spots });
                    }),
                    getApi().fetchUsers().then(function (users) {
                        dispatch({ type: 'SET_USERS', payload: users });
                    }),
                ]).then(navigateToApp);

            })

        }).catch(function (errorCode) {
            const error = 'Nieprawidłowy nick lub hasło';
            form.setFields([
                { name: 'username', errors: [''] },
                { name: 'password', errors: [error] }
            ]
            );
        });
    }

    console.log('render: LoginPage')

    return (
        <Layout>
            <div style={{ width: 300, margin: 'auto', }}>
                <img src={logo} style={{ width: '100%', padding: "25px 50px" }} alt="Logo" />
                <Form form={form} onFinish={onFinish} className="login-form">
                    <Form.Item
                        name="username"
                        rules={[{ required: true, message: 'Proszę podać nick' }]}
                    >
                        <Input prefix={<UserOutlined className="site-form-item-icon" />}
                            placeholder="Nick" />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: 'Proszę podać hasło' }]}
                    >
                        <Input prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            placeholder="Hasło" />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button">
                            Zaloguj się
                        </Button>
                        <br />
                        Nie masz konta? <Link to={AUTH_REGISTER}>Zarejestruj się</Link>
                        <br />
                        Nie pamiętasz hasła? <Link to={AUTH_PASSWORD_RESET_REQUEST}>Resetuj hasło</Link>
                    </Form.Item>
                </Form>
            </div>
        </Layout>
    );
}
