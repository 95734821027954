import React, {useState} from 'react';
import {Button, Checkbox, Form, Input, Layout} from 'antd';
import {getApi} from "./api";
import {Link} from "react-router-dom";
import {AUTH_LOGIN} from "./urls";
import {errorMessageToDisplay} from "./copy";


export function RegisterPage() {
    const [form] = Form.useForm();
    const [formSubmitted, setFormSubmitted] = useState(false);

    function onFinish(values) {
        let terms = [];

        if (values.gdpr) {
            terms.push({name: 'gdpr', version: 1});
        }

        if (values.tos) {
            terms.push({name: 'terms_and_conditions', version: 1});
        }

        return getApi().register(
            values.username,
            values.password,
            values.email,
            values.card_number,
            values.first_name,
            values.last_name,
            terms
        ).then(function () {
            setFormSubmitted(true);

        }).catch(function (errors) {
            if (errors.email) {
                form.setFields([
                        {name: 'email', errors: ['Podany email nie jest poprawny.']}
                    ]
                );
            }

            if (errors.username) {
                form.setFields([
                        {name: 'username', errors: ['Użytkownik o tym nicku jest juz zarejestrowany.']}
                    ]
                );
            }

            if (errors.password && errors.password.length > 0) {
                const passErr = errors.password.map(errorMessageToDisplay);

                form.setFields([
                        {name: 'password', errors: passErr}
                    ]
                );
            }
        });
    }

    console.log('render: RegisterPage')

    const formProps = {
        form: form,
        className: 'register-form',
        layout: 'vertical',
        validateMessages: {
            required: 'Pole wymagane',
        },
        onFinish: onFinish
    };


    const itemProps = {
        username: {
            name: 'username',
            label: 'Nick',
            rules: [
                {required: true},
                {
                    required: true,
                    pattern: new RegExp("^([A-Za-z0-9]+)$"),
                    message: 'Nick może składać się tylko z liter i cyfr',
                }
            ],
            tooltip: ('Nick w połączeniu z hasłem jest używany do logowania. ' +
                'Będzie też widoczny dla innych użytkowników przy Twoich rezerwacjach.')

        },
        password: {
            name: 'password',
            label: 'Hasło',
            rules: [{required: true}],
            tooltip: ('To hasło będzie potrzebne przy logowaniu.')
        },
        email: {
            name: 'email',
            label: 'Email',
            rules: [{required: true}],
            tooltip: ('Dzięki temu mailowi będziemy mogli się z Tobą skontaktować ' +
                'np. jeśli zapomnisz hasła. Żadnego spamu!')
        },
        firstName: {
            name: 'first_name',
            label: 'Imię',
            rules: [{required: true}],
            tooltip: ('Musimy znać Twoje imie, żeby wystawić zezwolenie.')
        },
        lastName: {
            name: 'last_name',
            label: 'Nazwisko',
            rules: [{required: true}],
            tooltip: ('Musimy znać Twoje nazwisko, żeby wystawić zezwolenie.')
        },
        cardNumber: {
            name: 'card_number',
            label: 'Nr karty wędkarskiej',
            rules: [{required: true}],
            tooltip: ('Musimy znać Twój numer karty wędkarskiej, żeby wystawić zezwolenie.')
        },
        gdpr: {
            name: 'gdpr',
            valuePropName: 'checked',
            initialValue: false,
            rules: [{
                required: true,
                transform: value => (value || undefined),  // Those two lines
                type: 'boolean'
            }],
        },
        tos: {
            name: 'tos',
            valuePropName: 'checked',
            initialValue: false,
            rules: [{
                required: true,
                transform: value => (value || undefined),  // Those two lines
                type: 'boolean'
            }],
        },
    };

    let view;
    if (formSubmitted) {
        view = (
            <>
                <h1>Dziękujemy za rejestrację</h1>
                <p>
                    Na razie jeszcze nie możesz się zalogować. Jak tylko potwierdzimy
                    Twoją wpłatę Twoje konto zostanie aktywowane i będziesz mógł rezerwować zasiadki.
                </p>
                <p>
                    Zespół PKK
                </p>
                <Link to={AUTH_LOGIN}>Powrót do strony głównej</Link>
            </>
        );
    } else {
        view = (
            <>
                <h1>Stwórz konto</h1>

                <Form {...formProps}>

                    <Form.Item {...itemProps.username}>
                        <Input placeholder="bogdan86"/>
                    </Form.Item>

                    <Form.Item {...itemProps.password}>
                        <Input placeholder="**********" type="password"/>
                    </Form.Item>

                    <Form.Item {...itemProps.email}>
                        <Input placeholder="bogdan86@gmail.com"/>
                    </Form.Item>

                    <Form.Item {...itemProps.firstName}>
                        <Input placeholder="Bogdan"/>
                    </Form.Item>

                    <Form.Item {...itemProps.lastName}>
                        <Input placeholder="Kowalski"/>
                    </Form.Item>

                    <Form.Item {...itemProps.cardNumber}>
                        <Input placeholder="004528/2008"/>
                    </Form.Item>

                    <Form.Item {...itemProps.tos}>
                        <Checkbox value={true}>
                            Akceptuję Regulamin Łowiska Brzeżonko
                            <a href="http://www.brzezonko.pl/regulamin/" target="_blank" rel="noreferrer"> więcej</a>
                        </Checkbox>
                    </Form.Item>

                    <Form.Item {...itemProps.gdpr}>
                        <Checkbox value={true}>
                            Wyrażam zgodę na przetwarzanie moich danych osobowych na
                            potrzeby wydania zezwolenie na amatorski połów ryb w obwodzie
                            rybackim jeziora Brzeżonko
                            <a href="http://www.brzezonko.pl/rodo/" target="_blank" rel="noreferrer"> więcej</a>
                        </Checkbox>
                    </Form.Item>

                    <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                        style={{marginBottom: '25px'}}>
                        Zarejestruj się
                    </Button>

                </Form>
            </>
        );
    }

    return (
        <Layout style={{overflowY: 'scroll', padding: '25px'}}>
            <div style={{maxWidth: 500, margin: 'auto',}}>
                {view}
            </div>
        </Layout>
    );
}