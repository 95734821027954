import moment from "moment";
import 'moment-timezone'

export const LOCALE_DATE_FORMAT = 'dd DD.MM.YYYY';
export const ISO_DATE_FORMAT = 'YYYY-MM-DD';


export function getCurrentBrzezonkoTime() {
    return moment.tz(moment(), "Europe/Warsaw");
}


export function getCurrentBrzezonkoDay() {
    return moment(toIso(getCurrentBrzezonkoTime()));
}


export function getCurrentBrzezonkoMonth() {
    return getCurrentBrzezonkoTime().month();
}


export function getCurrentYear() {
    return getCurrentBrzezonkoTime().year();
}


export function getFirstDayOfTheMonth(month) {
    return moment([getCurrentYear(), month]);
}


export function getLastDayOfTheMonth(month) {
    let date = getFirstDayOfTheMonth(month);

    date.add(1, 'month');
    date.subtract(1, 'days')

    return date;
}


export function isPastDate(date) {
    const now = getCurrentBrzezonkoTime();
    return now.isAfter(date, 'day');
}


export function isSameDate(d1, d2) {
    return moment(d1).format(ISO_DATE_FORMAT) === moment(d2).format(ISO_DATE_FORMAT);
}

export function isBeforeDate(d1, d2) {
    return moment(d1).isBefore(moment(d2), 'day');
}


export function isBetweenDates(date, low, high) {
    return moment(date.format(ISO_DATE_FORMAT)).isBetween(
        moment(low).format(ISO_DATE_FORMAT),
        moment(high).format(ISO_DATE_FORMAT),
        'day', '[]') // all inclusive;
}


export function localeDisplay(date) {
    return moment(date).format(LOCALE_DATE_FORMAT);
}


export function toIso(date) {
    return moment(date).format(ISO_DATE_FORMAT);
}