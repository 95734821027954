import {Modal} from "antd";
import {getApi} from "./api";
import {isSameDate, localeDisplay} from "./time";


export function ReservationCancelModal({visible, onOkCallback, onCancelCallback, reservation, spot}) {
    const spotName = (spot ? spot.name : '');
    let displayText;

    function onOk() {
        getApi().cancelReservation(reservation.id).then(onOkCallback);
    }

    console.log('render: ReservationCancelModal')

    const modalProps = {
        visible: visible,
        title: 'Odwołanie rezerwacji: ' + spotName,
        onOk: onOk,
        onCancel: onCancelCallback,
        okText: 'Odwołaj',
        cancelText: 'Pozostaw bez zmian',
        okButtonProps: {
            danger: true
        }
    };

    if (reservation) {
        if (isSameDate(reservation['start_date'], reservation['end_date'])) {
            displayText = 'Czy na pewno chcesz odwołać rezerwację z dnia ' + localeDisplay(reservation['start_date']);
        } else {
            displayText = ('Czy na pewno chcesz odwołać rezerwację ' +
                localeDisplay(reservation['start_date']) +
                ' - ' +
                localeDisplay(reservation['end_date']) + ' ?');
        }
    }

    return (
        <Modal {...modalProps}>
            <p>{displayText}</p>
        </Modal>
    )
}