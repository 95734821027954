import {
    OVERLAP_RESERVATION, PASSWORD_ENTIRELY_NUMERIC, PASSWORD_TOO_COMMON,
    PASSWORD_TOO_SHORT,
    PASSWORD_TOO_SIMILAR, SEASON_NOT_STARTED,
    TOO_LONG_RESERVATION,
    TOO_MANY_RESERVATIONS,
    NOT_ENOUGH_DAYS_AVAILABLE
} from "./api";

export function errorMessageToDisplay(code) {
    let message;

    if (code === OVERLAP_RESERVATION) {
        message = 'Ktoś już zarezerwował to stanowisko w tym okresie'
    } else if (code === TOO_MANY_RESERVATIONS) {
        message = 'Masz już 2 aktywne rezerwacje. Na razie nie możesz rezerwować więcej'
    } else if (code === TOO_LONG_RESERVATION) {
        message = 'Rezerwacja nie może być dłuższa niż 5 dni'
    } else if (code === PASSWORD_TOO_SHORT) {
        message = 'Hasło musi składać się z conajmniej 8 znaków.'
    } else if (code === PASSWORD_TOO_SIMILAR) {
        message = 'Hasło za bardzo podobne do nicku.'
    } else if (code === PASSWORD_TOO_COMMON) {
        message = 'Hasło za łatwe do zgadnięcia.'
    } else if (code === PASSWORD_ENTIRELY_NUMERIC) {
        message = 'Hasło nie może składać się z samych cyfr.'
    } else if (code === NOT_ENOUGH_DAYS_AVAILABLE) {
        message = 'Limit dni nie pozwala na rezerwację tak długiej zasiadki.'
    } else if (code === SEASON_NOT_STARTED) {
        message = 'Rezerwacje zaczynają się 29 marca o godz. 20:00'
    } else {
        message = 'Coś poszło nie tak, spróbuj jeszcze raz'
    }

    return message;

}
