export const ROOT = '/';

export const AUTH = '/auth';
export const AUTH_LOGIN = '/auth/login';
export const AUTH_REGISTER = '/auth/register';
export const AUTH_PASSWORD_RESET_REQUEST = '/auth/password_reset_request';
export const AUTH_PASSWORD_RESET_CONFIRM = '/auth/password_reset_confirm';

export const APP = '/app';
export const APP_CALENDAR = '/app/calendar';
export const APP_RESERVATIONS = '/app/reservations';
export const APP_DATA = '/app/license';


export function noAutologin(url) {
    const noAutologinUrls = [AUTH_REGISTER, AUTH_PASSWORD_RESET_REQUEST, AUTH_PASSWORD_RESET_CONFIRM];
    return noAutologinUrls.includes(url);
}