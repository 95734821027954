import { BrowserRouter as Router, Redirect, Route, Switch, useHistory } from "react-router-dom";
import { LoginPage } from "./LoginPage";
import { AppPage } from "./AppPage";
import {
    APP,
    AUTH,
    AUTH_LOGIN,
    AUTH_PASSWORD_RESET_CONFIRM,
    AUTH_PASSWORD_RESET_REQUEST,
    AUTH_REGISTER,
    noAutologin
} from "./urls";
import { configureApi, getApi } from "./api";
import { get, KEYS, set } from "./storage";
import Store, { Context } from "./context/Store";
import { useContext, useEffect, useState } from "react";
import { RegisterPage } from "./RegisterPage";
import { PasswordResetRequestPage } from "./PasswordResetRequestPage";
import { PasswordResetConfirmPage } from "./PasswordResetConfirmPage";
import LogRocket from 'logrocket';

function Routes() {
    // This is a separate component ran in <Router> context, so we can
    // pass history to configureApi (otherwise it would be undefined)

    let history = useHistory();
    let [loaded, setLoaded] = useState(false);

    function onUnauthorized() {
        //Order is important - app does not know how to render w/o state.user
        dispatch({ type: 'SET_USER', payload: undefined });
        dispatch({ type: 'SET_SPOTS', payload: [] });
        set(KEYS.AUTH_TOKEN, undefined);
        history.push(AUTH_LOGIN);
    }

    configureApi(
        process.env.REACT_APP_API_URL,
        () => get(KEYS.AUTH_TOKEN),
        (t) => set(KEYS.AUTH_TOKEN, t),
        onUnauthorized
    );

    // eslint-disable-next-line
    const [state, dispatch] = useContext(Context);

    useEffect(function () {

        if (noAutologin(history.location.pathname)) {
            setLoaded(true);
            return;
        }

        getApi().fetchOwnProfile().then(function (user) {
            dispatch({ type: 'SET_USER', payload: user })

            LogRocket.identify(user.id, {
                name: user.username,
                email: user.email
            });

            return Promise.all([
                getApi().fetchSpots().then(function (spots) {
                    dispatch({ type: 'SET_SPOTS', payload: spots })
                }),
                getApi().fetchUsers().then(function (users) {
                    dispatch({ type: 'SET_USERS', payload: users })
                }),
            ]);

        }).finally(function () {
            setLoaded(true);
        })
    }, [dispatch, history.location.pathname]);

    if (!loaded) {
        return (<></>)
    }

    return (
        <Switch>
            <Route path={AUTH}>
                <Route path={AUTH_LOGIN} component={LoginPage} />
                <Route path={AUTH_REGISTER} component={RegisterPage} />
                <Route path={AUTH_PASSWORD_RESET_REQUEST} component={PasswordResetRequestPage} />
                <Route path={AUTH_PASSWORD_RESET_CONFIRM} component={PasswordResetConfirmPage} />
            </Route>

            <Route path={APP} component={AppPage}>
            </Route>

            <Redirect from="/" to={AUTH_LOGIN}>
            </Redirect>
        </Switch>
    );

}


function App() {
    console.log('render: App')
    LogRocket.init('dfmtsv/cyberkrap2077');

    return (
        <Store>
            <Router>
                <Routes />
            </Router>
        </Store>
    )
}


export default App;
