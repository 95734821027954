const Reducer = (state, action) => {
    switch (action.type) {
        case 'SET_USER':
            return {
                ...state,
                user: action.payload
            };
        case 'SET_SPOTS':
            return {
                ...state,
                spots: action.payload
            };
        case 'SET_DAYS_AVAILABLE':
            return {
                ...state,
                daysAvailable: action.payload
            };
        case 'SET_USERS':
            return {
                ...state,
                users: action.payload
            };
        default:
            return state;
    }
};

export default Reducer;
