import { useContext } from "react";
import { Context } from "./context/Store";


export function HeaderText(props) {
    // eslint-disable-next-line
    const [state, dispatch] = useContext(Context);
    let text = props.text;

    // if (typeof(state.daysAvailable) === 'number') {
    //     text += ` - pozostało ${state.daysAvailable} dni`
    // }

    return text;
}
