import {isBeforeDate, isBetweenDates, isPastDate} from "./time";
import moment from "moment";


export function isDateDisabled(date, reservations, status, excludeReservation) {
    let isDisabled = true, disabledReason;

    if (excludeReservation) {
        reservations = reservations.filter((e) => e.id !== excludeReservation.id);
    }

    if (isPastDate(date)) {
        disabledReason = 'Ten termin już minął';
    } else if (reservations.find(element => isBetweenDates(date, element['start_date'], element['end_date']))) {
        disabledReason = 'Stanowisko zostało już zarezerwowane w tym terminie.';
    } else if (status.remaining === 0) {
        disabledReason =
            'Masz już ' + status.active_count + ' aktywne rezerwacje i na razie nie możesz rezerwować więcej. ' +
            'Dalsza rezerwacja będzie możliwa po zakończeniu najbliższej zasiadki.'
        ;
    } else if (isBeforeDate(status.range.max, date)) {
        disabledReason =
            'Odległa rezerwacja została już wykorzystana. Na razie możesz rezerwować tylko z kilkudniowym wyprzedzeniem. '
        ;
    } else {
        isDisabled = false;
    }

    return [isDisabled, disabledReason];
}


export function getLength(reservation) {
    return moment(reservation.end_date).diff(moment(reservation.start_date), 'days') + 1;
}

export function getPeopleNo(reservation) {
    return reservation.children ? reservation.children.lenght + 1 : 1;
}
