import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Card, message, Spin } from 'antd';
import { ReservationCards } from "./ReservationCards";
import { getApi, NOT_ENOUGH_DAYS_AVAILABLE } from "./api";
import { Context } from "./context/Store";
import { errorMessageToDisplay } from "./copy";
import { ReservationModal } from "./ReservationModal";
import { ReservationCancelModal } from "./ReservationCancelModal";
import moment from "moment";
import { getFirstDayOfTheMonth, getLastDayOfTheMonth, toIso } from "./time";


export function MyReservationsPage() {
    // eslint-disable-next-line
    const [state, dispatch] = useContext(Context);
    const [reservations, setReservations] = useState();
    const [reservation, setReservation] = useState();
    const [reservationStatus, setReservationStatus] = useState();
    const [spotReservations, setSpotReservations] = useState([]);
    const [spot, setSpot] = useState();
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState();

    const MODAL_EDIT_OR_CREATE = 'modal_edit_or_create';
    const MODAL_CANCEL = 'modal_cancel';

    const loadUserReservations = useCallback(function () {
        return getApi().fetchReservationsForUser(state.user.id).then(function (reservations) {
            setReservations(reservations);

        })
        // eslint-disable-next-line
    }, [])

    const loadReservationStatus = useCallback(() => {
        return getApi().fetchReservationStatus().then((status) => {
            dispatch({ type: 'SET_DAYS_AVAILABLE', payload: status.days_available })
            setReservationStatus(status);
        });
    }, [dispatch])

    useEffect(function () {
        setLoading(true);
        return Promise.all([loadUserReservations(), loadReservationStatus()]).then(function () {
            setLoading(false);
        })
    }, [loadUserReservations, loadReservationStatus]);

    function loadSpotReservations(spotId) {
        setLoading(true);

        const startDayLow = getFirstDayOfTheMonth(0).subtract(14, 'days');
        const startDayHigh = getLastDayOfTheMonth(11).add(14, 'days');

        return getApi().fetchReservationsForSpot(spotId, startDayLow, startDayHigh).then(function (reservations) {
            setSpotReservations(reservations);
            setLoading(false);
        })

    }

    function onReservationCreateOrUpdate([startDate, endDate], spotId, children) {
        setLoading(true);
        let promise, okMessage;

        if (reservation) {
            okMessage = 'Stanowisko zarezerwowne pomyślnie';
            promise = getApi().editReservation(reservation.id, spotId, toIso(startDate), toIso(endDate), children);
        } else {
            okMessage = 'Rezerwacja zmieniona pomyślnie';
            promise = getApi().createReservation(spotId, toIso(startDate), toIso(endDate), children)
        }

        return promise.then(function () {
            return Promise.all([loadUserReservations(), loadReservationStatus()]).then(function () {
                message.success(okMessage);
                setModal();
                return Promise.resolve()
            }
            );
        }).catch(function (code) {
            return Promise.reject(errorMessageToDisplay(code));
        }).finally(function () {
            setLoading(false);
        })
    }

    function onReservationApprove(reservationId) {
        return getApi().approveReservation(reservationId).then(() => {
            return Promise.all([loadUserReservations(), loadReservationStatus()]).then(function () {
                message.success('Rezerwacja potwierdzona pomyślnie');
            }
            );
        }).catch((code) => {
            let msg;
            if (code === NOT_ENOUGH_DAYS_AVAILABLE) {
                msg = 'Nie masz wystarczająco dni, żeby wziaść udziałw tej zasiadce'
            } else {
                msg = 'Coś poszło nie tak, spróbuj jeszcze raz.'
            }
            message.error(msg);
        })
    }

    function onSpotChangeInModal(spotId) {
        let spot = state.spots.find((e) => e.id === spotId)
        setSpot(spot);
        return loadSpotReservations(spot.id);
    }

    function onCancelSuccess() {
        setLoading(true);

        Promise.all([loadUserReservations(), loadReservationStatus()]).then(function () {
            message.success('Rezerwacja odwołana pomyślnie');
            setLoading(false);
            setModal();
        })
    }

    function getNotificationText() {
        if (!reservationStatus) {
            return;
        }

        if (reservationStatus.remaining === 0) {
            return (
                'Masz już ' + reservationStatus.active_count + ' aktywne rezerwacje. Na razie nie' +
                ' możesz rezerwować więcej. Dalsza rezerwacja będzie możliwa po zakończeniu najbliższej zasiadki.'
            );
        }

        const limitations = reservationStatus.limitations || [];
        if (limitations.find((e) => e.name === 'far_not_available')) {
            return (
                'Odległa rezerwacja została już wykorzystana. Na razie możesz ' +
                'rezerwować tylko z kilkudniowym wyprzedzeniem. '
            );
        }
    }

    const reservationModalProps = {
        visible: Boolean(modal === MODAL_EDIT_OR_CREATE),
        onOkCallback: onReservationCreateOrUpdate,
        onCancelCallback: () => setModal(undefined),
        onSpotChange: onSpotChangeInModal,
        startDate: reservation ? moment(reservation.start_date) : moment(),
        spot: spot ? spot : state.spots[0],
        spots: state.spots,
        reservation: reservation,
        spotReservations: spotReservations,
        reservationStatus: reservationStatus
    }

    const cancelModalProps = {
        visible: Boolean(modal === MODAL_CANCEL),
        onOkCallback: onCancelSuccess,
        onCancelCallback: () => setModal(undefined),
        spot: spot,
        reservation: reservation
    }

    function onReservationRequest(spot) {
        let spotToSet = spot ? spot : state.spots[0];

        setSpot(spotToSet);
        setReservation(undefined);
        setModal(MODAL_EDIT_OR_CREATE);
        loadSpotReservations(spotToSet.id);
    }

    function onReservationEditRequest(reservation, spot) {
        setSpot(spot);
        setReservation(reservation);
        setModal(MODAL_EDIT_OR_CREATE);
        loadSpotReservations(spot.id)
    }

    function onReservationApproveRequest(reservation, spot) {
        onReservationApprove(reservation.id);
    }

    function onReservationCancelRequest(reservation, spot) {
        setSpot(spot);
        setReservation(reservation);
        setModal(MODAL_CANCEL);
    }

    let notification = (<></>);
    if (getNotificationText()) {
        notification = (
            <Card style={{ border: '1px solid #1890ff', color: '#1890ff', marginBottom: '20px', display: 'hidden' }}>
                {getNotificationText()}
            </Card>);
    }

    return (
        <Spin spinning={loading}>
            {notification}
            <ReservationCards
                reservations={reservations}
                loading={loading}
                spots={state.spots}
                onReservationCreate={onReservationRequest}
                onReservationCancel={onReservationCancelRequest}
                onReservationEdit={onReservationEditRequest}
                onReservationApprove={onReservationApproveRequest}
                creationDisabled={reservationStatus ? reservationStatus.remaining === 0 : false}
            />
            <ReservationModal {...reservationModalProps} />
            <ReservationCancelModal {...cancelModalProps} />
        </Spin>
    )
}
