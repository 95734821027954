import { useContext } from 'react';
import { getCurrentBrzezonkoDay, isBeforeDate, isPastDate, isSameDate, localeDisplay } from "./time";
import { Button, Card, Empty, Spin, Tag, Tooltip } from "antd";
import { Context } from "./context/Store";
import moment from 'moment';


function Participant({ name, approved }) {
    return <span key={name}>
        <span>{name}</span>
        {approved === true ? <Tooltip title={`${name} potwierdził(a) uczestnictwo`}><span style={{marginLeft: '3px', marginRight: '10px'}}>✅</span></Tooltip> : null}
        {approved === false ? <Tooltip title={`${name} nie potwierdził(a) uczestnictwa`}><span style={{marginLeft: '3px', marginRight: '10px'}}>❌</span></Tooltip> : null}
    </span>
}

export function ReservationCards({ reservations, loading, onReservationCreate, onReservationCancel, onReservationEdit, onReservationApprove, creationDisabled }) {
    let cards = [], content;
    // eslint-disable-next-line
    const [state, dispatch] = useContext(Context);

    const style = {
        marginBottom: '10px',
    };

    function fromNow(date) {
        const days = moment(date).diff(getCurrentBrzezonkoDay(), 'days');

        if (days === 1) {
            return 'Jutro';
        }

        if (days === 2) {
            return 'Pojutrze';
        }

        return 'Za ' + days + ' dni';
    }

    function findSpotById(id) {
        return state.spots.find((elem) => elem.id === id);
    }

    function onCardReservationClick(spotId) {
        const spot = findSpotById(spotId);
        onReservationCreate(spot);
    }

    if (!reservations && loading) {
        return (
            <Spin>
                <Card loading={true} />
                <Card loading={true} />
                <Card loading={true} />
            </Spin>
        );
    }

    reservations.sort(function (a, b) {
        return (isBeforeDate(a['start_date'], b['start_date']) ? 1 : -1);
    });

    reservations.forEach(function (elem) {
        let tagColor, tagText;
        let start = elem['start_date'], end = elem['end_date'];
        const spot = findSpotById(elem['spot_id']);
        if (!spot) {
            return;
        }

        function onCancelClick() {
            onReservationCancel(elem, spot);
        }

        function onEditClick() {
            onReservationEdit(elem, spot);
        }

        function onApproveClick() {
            onReservationApprove(elem, spot);
        }

        const createButtonProps = {
            style: { marginRight: '8px' },
            onClick: () => onCardReservationClick(elem['spot_id']),
            size: 'small',
            disabled: creationDisabled
        };

        let buttons = [(
            <Button {...createButtonProps}>
                Rezerwuj ponownie
            </Button>)
        ];

        const buttonEdit = (<Button onClick={onEditClick} size='small' style={{ marginRight: '8px' }}>Edytuj</Button>);
        const buttonApprove = (<Button onClick={onApproveClick} size='small' type="primary" style={{ marginRight: '8px' }}>Potwierdź</Button>);
        const buttonCancel = (<Button onClick={onCancelClick} size='small' type="danger" style={{ marginTop: '5px' }}>Odwołaj</Button>);
        const needsApproval = elem.master && !elem.approved && !isPastDate(end);
        const approved = elem.master && !!elem.approved;

        const extraButtons = []
        if (needsApproval) {
            extraButtons.push(buttonApprove);
        } else if (!approved) {
            extraButtons.push(buttonEdit);
        }
        extraButtons.push(buttonCancel);

        if (isSameDate(start, getCurrentBrzezonkoDay())) {
            tagColor = 'red';
            tagText = 'Dziś';
            buttons.push(...extraButtons);
        } else if (isPastDate(end)) {
            tagColor = 'green';
            tagText = 'Zakończona';
        } else if (isPastDate(start)) {
            tagColor = 'red';
            tagText = 'W toku';
            buttons.push(...extraButtons);
        } else {
            tagColor = 'geekblue';
            tagText = fromNow(start);
            buttons.push(...extraButtons);
        }

        let title = (<div style={{ display: 'flex' }}>
            <span>{spot.name}</span>
            {needsApproval && <Tag color={'red'} style={{ marginLeft: '10px', marginTop: '1px' }}>NIE POTWIERDZONA</Tag>}
            <Tag style={{ marginLeft: 'auto' }} color={tagColor}>{tagText}</Tag>
        </div>
        );

        const participants = [Participant({ name: elem.user.username, approved: true })];
        const anonNo = elem.children.filter(c => !c.user).length;
        participants.push(...elem.children.filter(c => c.user).map(c => Participant({name: c.user.username, approved: c.approved})));
        if (anonNo) {
            participants.push(Participant({ name: `+${anonNo}os`}));
        }

        let card = (
            <Card type='inner' key={elem.id} title={title} style={style}>
                {!elem.master && <p>Uczestincy: {participants}</p>}
                {elem.master && <p>Utworzona przez: {elem.master.user.username}</p>}
                <p>Od: {localeDisplay(elem['start_date'])} godz. 16:00</p>
                <p>Do: {localeDisplay(moment(elem['end_date']).add(1, 'day'))} godz. 16:00</p>
                {buttons}
            </Card>
        );

        cards.push(card);

    });

    if (cards.length === 0) {
        content = (
            <Empty style={{ marginTop: '20px' }} description="Nie zarezerwowałeś jeszcze żadnej zasiadki">
                <Button onClick={() => onReservationCreate()}>Rezerwuj</Button>
            </Empty>)
    } else {
        content = cards;
    }

    return (
        <div>
            {content}
        </div>
    );

}
